.now{
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

.spacer{
  min-height: 50px;
}

.center{
  align-items: center;
  justify-content: center;
}